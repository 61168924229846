import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
 selector: 'app-validation-errors',
 template: `
   <ng-container *ngIf="shouldShowErrors()">
    <small class="text-danger validation-error">{{getError()}}</small>
   </ng-container>
 `,
})

export class ValidationErrorsComponent {

  private static readonly errorMessages = {
    'required': (params) => 'This field is required.',
    'min': (params) => 'Minimum allowed value for ##FIELD## is ' + parseInt(params.min, 10),
    'max': (params) => 'Maximum allowed value for ##FIELD## is ' + params.max,
    'minlength': (params) => '##FIELD## should be minimum ' + params.requiredLength + ' characters.',
    'maxlength': (params) => '##FIELD## should not be greater than ' + params.requiredLength + ' characters.',
    'pattern': (params) => 'Should be a valid',
    'email': (params) => 'Invalid Email.',
    'validateNumber': (params) => 'Should be valid number.',
    'validateEmail': (params) => 'Should be vaild Email.',
    'owlDateTimeMin': (params) => 'Date Time value must after ' + formatDate(params.min, 'MM/dd/yyyy hh:mm a', 'en'),
    'owlDateTimeParse': (params) => 'Invalid Date Time value.',
    'noWhitespace': (params) => 'This field is required.',
    'validatePricingField': (params) => '##FIELD## should have only number or "+".',
    'invalidPassword': (params) => '##FIELD## must be 8 characters including 1 special character and 1 numeric character.',
    'invalidAmount': (params) => 'Invalid Amount',
  };

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  @Input() submitted: boolean;
  @Input() fieldName: string;

  shouldShowErrors(): boolean {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched || this.submitted);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map(field => this.getMessage(field, this.control.errors[field], this.control));
  }

  getError(): string {
    const errors = Object.keys(this.control.errors).map(field => this.getMessage(field, this.control.errors[field], this.control));
    return errors[0];
  }

  private getMessage(type: string, params: any, control: any) {
    let fname = '';
    fname = this.getControlName(control);
    let msg = '';
    if (ValidationErrorsComponent.errorMessages[type]) {
      msg = ValidationErrorsComponent.errorMessages[type](params);
    }
    if (fname) {
      fname = fname.replace(new RegExp('_', 'g'), ' ');
      fname = fname.replace(' id', '').toLowerCase();
      fname = fname.replace(/\b\w/g, l => l.toUpperCase());
      if (ValidationErrorsComponent.errorMessages[type]) {
        return msg.replace('##FIELD##', fname);
      }
    }

    return '';
  }

  getControlName(c: AbstractControl): string | null {
    if (this.fieldName && this.fieldName !== '') {
      return this.fieldName;
    }
    if (c.parent) {
      const formGroup = c.parent.controls;
      return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }

    return null;
  }

}
