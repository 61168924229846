import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';

// third-party module imports
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

// custom module imports
import {
  SpinnerModule,
  TableModule,
  AutocompleteModule,
} from './modules';

// custom component imports
import {
  ModalPopupComponent,
  IconComponent,
  LoaderComponent,
  SpinnerComponent,
  ConfirmationDialogComponent,
  ValidationErrorsComponent,
  AccordionComponent,
  ServerValidationErrorComponent,
  ChangePasswordComponent,
  StatusLabelComponent,
  AddressComponent,
} from './components';

// custom service imports
import {
  ToasterService,
  ConfirmationDialogService,
  StorageService,
  AuthService,
  ApiService,
  SharedService,
} from './services';

import {
  CurrencyFormat,
  CapitalizeFirstPipe,
  FormatPhoneNumberPipe,
  UnitFormat
} from './pipes';

import {
  IsValidNumberDirective,
  TrimWhitespaceDirective
} from './directive';

import { AccessChecker, IsGrantedDirective } from './security';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // third-party modules
    NgxWebstorageModule.forRoot({ prefix: 'sif', separator: '|'}),
    NgbModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    // custom modules
    SpinnerModule,
    TableModule,
    AutocompleteModule,
  ],
  declarations: [
    SpinnerComponent,
    LoaderComponent,
    IconComponent,
    ModalPopupComponent,
    ConfirmationDialogComponent,
    ValidationErrorsComponent,
    ServerValidationErrorComponent,
    AccordionComponent,
    IsGrantedDirective,
    CurrencyFormat,
    CapitalizeFirstPipe,
    ChangePasswordComponent,
    FormatPhoneNumberPipe,
    StatusLabelComponent,
    AddressComponent,
    IsValidNumberDirective,
    TrimWhitespaceDirective,
    UnitFormat
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,

    // third-party modules
    NgbModule,
    OwlDateTimeModule,

    // custom modules
    SpinnerModule,
    TableModule,
    AutocompleteModule,

    // custom components
    SpinnerComponent,
    LoaderComponent,
    IconComponent,
    ModalPopupComponent,
    ConfirmationDialogComponent,
    ValidationErrorsComponent,
    ServerValidationErrorComponent,
    AccordionComponent,
    IsGrantedDirective,
    ChangePasswordComponent,
    StatusLabelComponent,
    AddressComponent,

    // custom pipes
    CurrencyFormat,
    CapitalizeFirstPipe,
    FormatPhoneNumberPipe,
    UnitFormat,

    IsValidNumberDirective,
    TrimWhitespaceDirective
  ],
  providers: [
    ToasterService,
    ConfirmationDialogService,
    StorageService,
    SharedService,
    AccessChecker,
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MY_NATIVE_FORMATS
    }
  ],
  entryComponents: [ ConfirmationDialogComponent ]
})
export class SharedModule { }
